import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description:打印入库交接单
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/16087
 */
export function acceptancePrint(data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt/acceptance/print', data);
}

/**
 * @description:加密入库凭证，用于提供给客户不需要登录查看入库凭证
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/18387
 */
export function encryptAcceptancePrint(data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt/encrypt/acceptance/print', data);
}

/**
 * @description:批次字段列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15013
 */
export function batchFieldList() {
  return wmsPlusHttp.post('/warehouse_management_system/batch_field/list', {});
}
