<template>
  <div v-loading="loading.featchDate" class="print-page">
    <div class="print-btn">
      <PrintButton
        :type="PRINT_BUTTON.type"
      />
      <div class="print-btn">
        <div class="margin-24">
          显示批属性:
          <el-select
            v-model="selectBatchAttributes"
            multiple
            :multiple-limit="2"
            placeholder="请选择"
          >
            <el-option
              v-for="item in batchAttributes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <p class="margin-24">
          说明:最多可选择2个批属性。
        </p>
      </div>
    </div>

    <div v-for="(printList, printNumber) in prints" :key="printNumber">
      <component
        :is="printList.component"
        :batch-attributes="dynamicList"
        :print-list="printList"
      />
    </div>
  </div>
</template>

<script>
import loadingMixin from '@thales/loading';
import PrintButton from '../components/PrintButton/PrintButton.vue';
import { PRINT_BUTTON } from '../components/PrintButton/config';
import { acceptancePrint, encryptAcceptancePrint, batchFieldList } from './api';
import Normal from './normal/index.vue';
import config from './config';

export default {
  name: 'DeliveryListNormal',
  components: {
    PrintButton,
    Normal,
  },
  mixins: [loadingMixin],
  data() {
    return {
      PRINT_BUTTON,
      config,
      receiptOrderIds: [], // ids
      prints: [], // 批量打印数据
      batchAttributes: [{ value: 'productDate', label: '生产日期', type: 'date' }], // 批属性
      selectBatchAttributes: ['productDate'], // 默认选中生产日期
      encrypt: false,
      loading: {
        featchDate: false,
      },
    };
  },
  computed: {
    dynamicList() {
      return this.selectBatchAttributes.map((item) => this.batchAttributes.find(
        (attr) => attr.value === item,
      ));
    },
  },
  created() {
    const { ids, encrypt } = this.$route.query;
    this.encrypt = encrypt;
    if (Array.isArray(ids)) {
      this.receiptOrderIds = ids;
    } else {
      this.receiptOrderIds = [ids];
    }
    this.init();
  },
  methods: {
    async init() {
      this.getBatchAttributes();
      this.featchDate();
    },
    // 获取批次字段
    async getBatchAttributes() {
      const resolve = await batchFieldList();
      resolve.forEach((item) => {
        if (!this.batchAttributes.some((batch) => batch.value === item.fieldCode)) {
          this.batchAttributes.push({
            value: item.fieldCode, label: item.fieldName, type: item.component,
          });
        }
      });
    },
    async featchDate() {
      let prints = [];
      if (this.encrypt === 'true') {
        prints = await encryptAcceptancePrint({ receiptOrderIds: this.receiptOrderIds });
      } else {
        prints = await acceptancePrint({ receiptOrderIds: this.receiptOrderIds });
      }
      this.prints = prints.map((item) => {
        const { receiptOrder: publicPrint = {}, acceptanceRecords: tables = [] } = item;
        return {
          publicPrint,
          tables,
          component: config.DEFAULT,
        };
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.print-page {
  width: 1123px;
  margin: 0 auto;
}

.print-btn {
  display: flex;
  align-items: baseline;

  .margin-24 {
    margin-left: 24px;
  }
}

@media print {
  .print-btn {
    display: none;
  }
}
 </style>
