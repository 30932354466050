export default {
  // 打印单公共信息配置
  PUBLIC: [
    [{
      label: '所属货主:',
      value: 'shipperName',
      width: 1,
    }, {
      label: '供应商:',
      value: 'supplierName',
      width: 1,
    }, {
      label: '入库日期:',
      value: 'bookingTime',
      width: 1,
    }],
    [{
      label: '入库类别:',
      value: 'receiptType',
      width: 1,
    }, {
      label: '客户订单号:',
      value: 'customerOrderNo',
      width: 1,
    }, {
      label: '入库单号:',
      value: 'receiptOrderNo',
      width: 1,
    }],
    [{
      label: '备注:',
      value: 'remark',
    }],
  ],
  // 签名
  FOOTER_SIGN: [
    [{
      label: '异常记录：',
      width: 1,
    }, {
      value: '',
      width: 2,
    }, {
      label: '异常是否经客户授权：',
      width: 1,
    }, {
      value: '',
      width: 2,
    }],
    [{
      label: '验收方（签字或盖章）',
      width: 1,
      align: 'center',
    }, {
      label: '送货司机',
      width: 1,
      align: 'center',
    }, {
      label: '送货车辆',
      width: 1,
      align: 'center',
    }, {
      label: '身份证号码',
      width: 1,
      align: 'center',
    }],
    [{
      value: '',
      height: '56px',
      width: 1,
    }, {
      value: '',
      height: '56px',
      width: 1,
    }, {
      label: '',
      height: '56px',
      width: 1,
    }, {
      value: '',
      height: '56px',
      width: 1,
    }],
    [{
      label: '第一联：单证联',
      width: 1,
      align: 'center',
    }, {
      label: '第二联：财务联',
      width: 1,
      align: 'center',
    }, {
      label: '第三联：仓库联',
      width: 1,
      align: 'center',
    }, {
      label: '第四联：客户联',
      width: 1,
      align: 'center',
    }],
  ],
};

export const getTableHeaders = ({
  batchAttributes = [],
  showShelfLife = true,
}) => {
  const dynamicList = batchAttributes.map((item) => ({
    label: item.label, width: '100px', prop: item.value, class: 'letter-spacing-1',
  }));
  const showShelfLifeList = showShelfLife ? [{
    label: '保质期(天)', prop: 'shelfLife', width: '60px', mediumLength: 4, maxLength: 8,
  },
  {
    label: '允收期', prop: 'dueInDays', width: '60px', mediumLength: 4, maxLength: 8,
  }] : [];

  return [
    { label: '序号', prop: 'index', width: '30px' },
    {
      label: '货主货品编码', prop: 'shipperGoodsCode', width: '110px', class: 'letter-spacing-1',
    },
    {
      label: '货品名称', prop: 'goodsName', mediumLength: 12,
    },
    {
      label: '规格', prop: 'goodsSpecifications', width: '80px', mediumLength: 4, maxLength: 8,
    },
    { label: '温层', prop: 'temperatureLayer', width: '36px' },
    {
      label: '预约数量', prop: 'amount', width: '70px', mediumLength: 4, maxLength: 8,
    },
    {
      label: '实收数量', prop: 'completedAmount', width: '70px', mediumLength: 4, maxLength: 8,
    },
    ...dynamicList,
    {
      label: '实收重量', prop: 'weight', width: '70px', mediumLength: 4, maxLength: 8,
    },
    ...showShelfLifeList,
    { label: '备注', width: '108px' },
  ];
};
