<template>
  <div class="print-list">
    <print-header
      :order="publicPrint.receiptOrderNo"
      :title="config"
      :show-ext="false"
      :print-config="printList"
    />
    <div class="print-public">
      <div
        v-for="(row, index) in fileds.PUBLIC"
        :key="index"
        class="row"
      >
        <div
          v-for="item in row"
          :key="item.value"
          class="col"
          :style="{'flex': item.width}"
        >
          <p>
            {{ item.label }}
            <span :style="item.textStyle">{{ publicPrint[item.value] }}</span>
          </p>
        </div>
      </div>
    </div>
    <print-table
      class="table"
      :headers="tableHeaders"
      :list="tables"
    />
    <div class="footer-sign">
      <div
        v-for="(row, index) in fileds.FOOTER_SIGN"
        :key="index"
        class="row border"
      >
        <div
          v-for="(item, i) in row"
          :key="i"
          class="col"
          :style="{'flex': item.width, 'height': item.height}"
        >
          <p v-if="item.label" :style="{textAlign: item.align}">
            {{ item.label }}
          </p>
          <p v-else>
            {{ publicPrint[item.value] }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getTemperatureLayer from '@/utils/temperatureLayer';
import unitNumber, { BIG_NUMBER } from '@/utils/unitNumber';
import moment from '@/utils/moment';
import { YMD } from '@/constant/timeFormat';
import { StoreBillTypeText } from '@/constant/task';
import PrintHeader from '../../components/PrintHeader/PrintHeader.vue';
import PrintTable from '../../components/PrintTable/PrintTable.vue';
import config from './config';
import fileds, { getTableHeaders } from './fileds';

export default {
  name: 'DeliveryListNormalNor',
  components: {
    PrintHeader,
    PrintTable,
  },
  props: {
    batchAttributes: {
      type: Array,
      default: () => [],
    },
    printList: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      config,
      fileds,
      publicPrint: {},
      tables: [],
    };
  },
  computed: {
    tableHeaders() {
      const showShelfLife = this.printList.tables.some((item) => item.shelfLife);
      return getTableHeaders({
        batchAttributes: this.batchAttributes,
        showShelfLife,
      });
    },
  },
  created() {
    this.tables = this.formatTable();
  },
  methods: {
    formatTable() {
      const { tables: list = [], publicPrint = {} } = this.printList;
      this.publicPrint = {
        ...publicPrint,
        bookingTime: moment.format(publicPrint.bookingTime),
        receiptType: StoreBillTypeText[publicPrint.receiptType],
      };
      let bigAmountTotal = 0;
      let smallAmountTotal = 0;
      let bigCompletedAmountTotal = 0;
      let smallCompletedAmountTotal = 0;
      let weightTotal = 0;
      const tables = list.map((item, index) => {
        const attributes = this.getBatchManage(item.lotAttribute);

        const amount = unitNumber.calculate({ ...item, smallUnitNumber: item.amount }, BIG_NUMBER);
        const completedAmount = unitNumber.calculate({
          ...item, smallUnitNumber: item.completedAmount,
        }, BIG_NUMBER);

        bigAmountTotal += amount.bigUnitNumber * 1000;
        smallAmountTotal += amount.smallUnitNumber * 1000;
        bigCompletedAmountTotal += completedAmount.bigUnitNumber * 1000;
        smallCompletedAmountTotal += completedAmount.smallUnitNumber * 1000;

        weightTotal += item.weight * 1000;
        return {
          ...item,
          ...attributes,
          amount: item.amount ? amount.txt : '',
          completedAmount: item.completedAmount ? completedAmount.txt : '',
          temperatureLayer: getTemperatureLayer(item.temperatureLayer),
          weight: `${item.weight}kg`,
          index: index + 1,
        };
      });
      const row = {
        shipperGoodsCode: '总计',
        amount: `${bigAmountTotal / 1000}整${smallAmountTotal / 1000}零`,
        completedAmount: `${bigCompletedAmountTotal / 1000}整${smallCompletedAmountTotal / 1000}零`,
        weight: `${weightTotal / 1000}kg`,
      };

      tables.push(row);
      return tables;
    },

    // 动态批次字段处理
    getBatchManage(attributes = {}) {
      const attrObj = {};
      Object.keys(attributes).forEach((key) => {
        this.batchAttributes.forEach((item) => {
          if (item.type === 'date' && item.value === key) {
            attrObj[key] = attributes[key] ? moment.format(attributes[key], YMD) : '';
          }
        });
      });
      return {
        ...attributes,
        ...attrObj,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.print-list {
  page-break-after: always;
}

.row {
  display: flex;

  .col {
    span {
      font-weight: 600;
    }

    .increase-size {
      font-size: 20px;
    }
  }
}

.row.border {
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;

  .col {
    height: 28px;
    padding: 2px 0;
    border-right: 1px solid #000;
  }
}

.table {
  border-collapse: collapse;
}

.footer-sign {
  p {
    padding: 0 10px;
    font-size: 14px;
  }
}

@media print {
  $paddingTop: 10px;

  .print-list {
    padding-top: $paddingTop;
  }
}
 </style>
